.home-billboard{
  .caption{
    width:100%;
    h2{
      margin: 20px 0;
      color: #FFFFFF;
    }
    &.left{
      float: left;
      h2{
        text-align: left;
      }
      .round-button{
        float: left;
      }
    }
    &.center{
      text-align: center!important;
      h2{
        text-align: center!important;
      }
      .round-button{
        margin: auto;
      }
    }
    &.right{
      float: right;
      h2{
        text-align: right;
      }
      .round-button{
        float: right;
      }
    }
    padding: 20px;
  }
  h2{
    text-align: right!important;
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: 300;
    line-height: 4rem;
  }
  .round-button,.round-button-alt{
    width: 20%;
    @media(max-width: 767px){
      width: 50%;
    }
    .round{
      padding-top: 45%;
      font-size: 2.3rem;
    }
  }

}
