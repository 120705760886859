@import "_variables";

.content-column{
  &.light{
    h2,h3,p{
      color: #FFFFFF;
    }
  }
  &.dark{
    h2,h3,p{
      color: $body_text_color;
    }
  }
}
