@import "_variables";

.full-screen-billboard {
  border-bottom: 2px $primary_color solid;
  &.light{
    h2{
      color: $primary_color;
    }
  }
  h2{
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0;
    text-shadow: 3px 2px 4px rgba(0,0,0,.2)!important;
    @media(max-width: 767px){
      font-size: 2rem!important;
      line-height: 2.5rem!important;
    }
  }
}
