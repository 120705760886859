@import "_variables";
html{
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
}
body{
    font-family: 'Open Sans', sans-serif!important;
    background-color: #F4F4F4;
}
.btn.btn-transparent-secondary:hover{
  background-color: $secondary_color!important;
  color: #FFFFFF!important;
}
.btn.btn-secondary-on-white:hover{
  background-color: $secondary_color!important;
  color: #FFFFFF!important;
  border-color: $secondary_color!important;
}
.btn.btn-primary-on-white:hover{
  background-color: $primary_color!important;
  color: #FFFFFF!important;
  border-color: $primary_color!important;
}
.title-image-back{
  .tag{
    left: auto!important;

    h1{
      font-size: 3rem;
    }
  }
  &.light{
    .tag{
      h1{
        color: $primary_color;
        text-shadow: 1px 1px 3px rgba(0,0,0,.4);
      }
    }
  }
}
a.no-link{
  &:hover{
    text-decoration: none;
    cursor: default;
  }
}
p{
  font-size: 1.4rem;
}
h2{
  font-size: 2.6rem;
}
h3{
  font-size: 2.2rem;
}
.btn{
  border-width: 2px;
}

nav.navbar.bootsnav{
  .mobile-phone-button{
    @media (max-width: 991px) and (min-width: 768px){
      margin-top: 20px;
      width: 65%;
    }
    @media(max-width: 767px){
      margin-top: 0px;
    }
  }
  .phone{
    color: $primary_color;
    margin-top: 5px;
  }
  .navbar-brand{
    @media(min-width: 1200px){

    }
    @media(min-width: 992px){
      position: absolute;
      top: 0;
      img{
        max-height: 85px;
      }
    }
    @media(max-width: 991px){
      float: left!important;
      img{
        max-height: 55px;
        margin-right: 15px;
      }
    }
  }
  .navbar-header{
    .fa.fa-bars, .fa.fa-times{
      font-size: 3.2rem;
    }
  }
  .navbar-toggle{
    float: right;
    margin-right: 0;
  }
  ul.nav{
    li {
      @media(max-width: 767px){
        display: block!important;
      }
      &.dropdown {

        a.dropdown-toggle:after{
          display: none;
        }
      }
      a{
        @media(min-width: 992px){
          padding: 20px 10px;
        }
        padding: 20px 15px;
        text-transform: uppercase;
        font-size: 1rem;
      }
    }
  }
}
.white-box-header{
  background-color: $secondary_color;
  padding: 10px;
  h3{
    text-align: center;
    font-size: 1.4rem;
    margin: 0;
    color: #FFFFFF;
  }
}

.white-box{
  background-color: #ffffff;
  border-bottom: 2px $primary_color solid;
  padding: 20px;
  h3{
    color: $body_text_color!important;
    font-size: 1.8rem;
    margin-top: 0;
  }
  p{
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ul{
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    li{
      color: $primary_color;
      font-size: 1.2rem;
    }
  }
  img{
    max-width: 100%;
  }
  .btn{
    padding-left: 35px;
    padding-right: 35px;
  }
}

.cta-box{
  background-color:$primary_color;
  .content-area{
    text-align: center;
    padding: 15px 25px;
    p{
      color: #FFFFFF;
    }
  }
}
.single-service{
  .full-screen-billboard.center h2{
    display: none;
  }
  h3{
    color: $body_text_color;
  }
  .spec-box{
    margin-top: 40px;
    margin-bottom: 40px;
    h3{
      color: $primary_color;
      font-size: 1.3rem!important;
      text-transform: uppercase;
    }
    .white-box{
      border: 2px $primary_color solid;
    }
  }
  .white-box{
    h4{
      font-size: 1.6rem;
      margin-top: 0;
      text-align: center;
      color: $body_text_color;
    }
  }
  .case-study-box{
    margin-top: 20px;
    margin-bottom: 20px;
    .text{
      text-align: center;
      background-color: $primary_color;
      padding: 25px;
      color: #FFFFFF;
      .btn{
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }

  .capabilities-header{
    background-color: $secondary_color;
    padding: 10px;
    h3{
      text-align: center;
      font-size: 1.4rem;
      margin: 0;
      color: #FFFFFF;
    }
  }
  .sidebar{
    margin-top: 75px;
    @media(max-width: 767px){
      margin-top: 20px;
    }
  }

  /*MOBILE */
  .title-collapse{
    color: $body_text_color;
    border-bottom: 2px $primary_color solid;
    padding-bottom: 5px;
    .service-collapse-link{
      color: $primary_color;
      font-size: 2rem;
    }
  }
  .content-collapse{
    margin-top: 10px;
  }
}
.cta-block{
  .action-buttons{
    float:none!important;
  }
}
.half-half{
  background: linear-gradient(
   to right,
   $primary_color 0%,
   $primary_color 50%,
   $secondary_color 50%,
   $secondary_color 100%
 );
}
.case-study-testimonial-slider{
  margin: 50px 0;
  .swiper-slide{
    background-color: #FFFFFF;
    width: 100%;
    .text{
      padding: 25px;
    }
    .name, .location{
      width: 100%;
      float: left;
    }
  }
}

/* SINGLE CASE STUDY */
.case-study-slider{
  background-color: #474747;
  .swiper-slide{
    background-color: transparent;
  }
}

#topFooter{
  background-color: $secondary_color;
  padding: 35px 15px;
  .footer-logo{
    padding-right: 25px;
  }
  p{
    color: #FFFFFF;
    font-size: 1.4rem;
  }
  .btn{
    padding-left: 35px;
    padding-right: 35px;
  }
}
.base-footer{
  background-color: #292929;
  ul{
    list-style-type: none;
    padding-left: 10px;
    li{
      font-size: 1rem!important;
      line-height: 1.2rem!important;
      a{
        color: #E7E7E7;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
  }
  small{
    a{
      color: $primary_color;
      font-size: .7rem;
      text-decoration: none;
    }
    color: $primary_color;
    font-size: .7rem;
  }
}
